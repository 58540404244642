import { Theme } from "../../../element/types";
import { createIcon, iconFillColor } from "../../../components/icons";

export const crispSubtypeIcon = ({ theme }: { theme: Theme }) =>
  createIcon(
    <path
      d="M3.00098 16.1691C6.28774 13.9744 19.6399 2.8905 22.7215 3.00082C25.8041 3.11113 19.1158 15.5488 21.4962 16.8309C23.8757 18.1131 34.4155 11.7148 37.0001 10.6919"
      stroke={iconFillColor(theme)}
      strokeWidth={2}
      strokeLinecap="round"
      fill="none"
    />,
    { width: 40, height: 20, mirror: true },
  );
